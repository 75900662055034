export default {
    methods: {
        addErrors(errors, to)
        {
            console.log(errors)
            let newErrors = {}
            errors.forEach(error =>  {
                newErrors[error.Field] = [
                    ...(errors[error] ?? []),
                    error.Msg
                ]
            })
            return {
                ...to,
                ...newErrors
            }
        }
    },
}