<template>
    <button 
    @click="$emit('click')" 
    :class="classes" 
    data-toggle="tooltip" 
    :title="tooltipCapitalized"
    data-delay='{ "show": 0, "hide": 3000}'
    :disabled="disabled"
    >
    <div v-if="loading" class="btn-icon-rounded-loader"></div>
    <i :class="icon" class="mr-5p"></i>{{ text | capitalize }}</button>
</template>
<script>
export default {
    name: "BtnSave",
    props: {
        type: String,
        tooltip: String,
        i: String,
        disabled: Boolean,
        loading: Boolean,
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        classes() {
            return 'btn btn-icon-rounded btn-icon-' + this.type + (this.small ? ' small' : '')
        },
        icon() {
            return 'fas fa-' + this.i
        },
        tooltipCapitalized() {
            if (this.tooltip.length == 0) {
                return ''
            }

            return this.tooltip[0].toUpperCase() + this.tooltip.substring(1)
        }
    },
}
</script>
