import Service from './Service'

export default class ItemService extends Service {
    constructor() {
        super(ItemService.API);
    }

    getItemColors(id, signal) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet(this.api + '/' + id + '/item-colors', null, signal);
    }
}

ItemService.API = "/items"