import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'

Vue.config.productionTip = false
Vue.config.devtools = true

// import services
import "@/services"

//import directives
import "@/directives"

//import all widget in admin
import '@/components/widget'

//import all buttons in admin
import '@/components/buttons'

//import all utils
import '@/utils'


Vue.use(Vuelidate)
Vue.use(VueFilterDateFormat)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  beforeCreate() {
      this.$store.dispatch('initiateAuthStore')
  }
}).$mount('#app')
