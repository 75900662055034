import Service from './Service'

export default class StockService extends Service {
    constructor() {
        super(StockService.API);
    }

    getBy(itemColorId, sizeId) {
        if([null, undefined, ""].includes(itemColorId)) {
            return new Promise((resolve, reject) => {
                reject({message: "itemColorId is null or undefined"})
            })
        }

        if([null, undefined, ""].includes(sizeId)) {
            return new Promise((resolve, reject) => {
                reject({message: "sizeId is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/GetBy', {
            itemColorId: itemColorId,
            sizeId: sizeId            
        });
    }

    getEvents(stockId) {
        if([null, undefined, ""].includes(stockId)) {
            return new Promise((resolve, reject) => {
                reject({message: "stockId is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/GetEvents', {
            stockId: stockId
        });
    }

    getQuantity(stockId) {
        if([null, undefined, ""].includes(stockId)) {
            return new Promise((resolve, reject) => {
                reject({message: "stockId is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/GetQuantity', {
            StockId: stockId
        });
    }

    getQuantityBy(itemColorId, sizeId) {
        if([null, undefined, ""].includes(itemColorId)) {
            return new Promise((resolve, reject) => {
                reject({message: "itemColorId is null or undefined"})
            })
        }

        if([null, undefined, ""].includes(sizeId)) {
            return new Promise((resolve, reject) => {
                reject({message: "sizeId is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/GetQuantityBy', {
            itemColorId: itemColorId,
            sizeId: sizeId            
        });
    }

    supply(itemColorId, sizeId, movement) {
        if([null, undefined, ""].includes(itemColorId)) {
            return new Promise((resolve, reject) => {
                reject({message: "itemColorId is null or undefined"})
            })
        }

        if([null, undefined, ""].includes(sizeId)) {
            return new Promise((resolve, reject) => {
                reject({message: "sizeId is null or undefined"})
            })
        }

        if([null, undefined, 0].includes(movement)) {
            return new Promise((resolve, reject) => {
                reject({message: "movement is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/Supply', {
            itemColorId: itemColorId,
            sizeId: sizeId,
            movement: Number.parseInt(movement)
        });
    }

    declareLost(itemColorId, sizeId, movement) {
        if([null, undefined, ""].includes(itemColorId)) {
            return new Promise((resolve, reject) => {
                reject({message: "itemColorId is null or undefined"})
            })
        }

        if([null, undefined, ""].includes(sizeId)) {
            return new Promise((resolve, reject) => {
                reject({message: "sizeId is null or undefined"})
            })
        }

        if([null, undefined, 0].includes(movement)) {
            return new Promise((resolve, reject) => {
                reject({message: "movement is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/DeclareLost', {
            itemColorId: itemColorId,
            sizeId: sizeId,
            movement: Number.parseInt(movement)
        });
    }
}

StockService.API = "/stocks"