import Vue from 'vue';

import api from '@/services/api'
import Service from '@/services/Service'
import CategoryService from '@/services/CategoryService'
import SubcategoryService from '@/services/SubcategoryService'
import FilterService from '@/services/FilterService'
import ItemService from '@/services/ItemService'
import ItemColorService from '@/services/ItemColorService'
import CollectionService from '@/services/CollectionService'
import AuthService from '@/services/AuthService'
import UserService from '@/services/UserService'
// import MarkService from '@/services/MarkService'
import ColorService from '@/services/ColorService'
// import ImageService from '@/services/ImageService'
import StockService from '@/services/StockService'

// add sevices as global variables
Vue.prototype.$http = api;

Vue.prototype.$service = new Service()
Vue.prototype.$categoryService = new CategoryService()
Vue.prototype.$subcategoryService = new SubcategoryService()
Vue.prototype.$filterService = new FilterService()
Vue.prototype.$itemService = new ItemService()
Vue.prototype.$itemColorService = new ItemColorService()
Vue.prototype.$collectionService = new CollectionService()
Vue.prototype.$authService = new AuthService()
Vue.prototype.$userService = new UserService()
// Vue.prototype.$markService = new MarkService()
Vue.prototype.$colorService = new ColorService()
Vue.prototype.$stockService = new StockService()
// Vue.prototype.$imageService = new ImageService()