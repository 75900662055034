<template>
    <div></div>
</template>

<script>
export default {
    name: "DashboardView",
    components: {},
    mixins: [],
    props: {},
    data() { return {} },
    computed: {},
    methods: {},
    watch: {},
    setup() {},
    created() {},
    mounted() {},
    beforeDestroy() {}
}
</script>