import Vue from 'vue';
import buildQueryString from "./buildQueryString"

export default class Service {
    api = null;
    http = null

    constructor(api) {
        this.api = api;
        this.http = Vue.prototype.$http
    }

    getAll(queries, signal)
    {
        return this.requestGet(this.api, queries, signal);
    }

    get(id, queries, signal)
    {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet(this.api + '/' + id, queries, signal);
    }

    post(payload)
    {      
        return this.requestPost(this.api, payload);
    }

    put(id, payload)
    {  
        if (id == null || id == undefined) {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestPut(this.api + '/' + id , payload);
    }

    delete(id)
    {  
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestDelete(this.api + '/' + id);
    }

    requestGet(to, queries, signal)
    {
        let config = { 
            headers: { 
                'Accept': 'application/json' 
            } 
        }

        if(signal) {
            config.signal = signal;
        }

        return this.http.get(to + buildQueryString(queries), config);
    }

    requestPost(to, payload)
    {
        let config = { headers: { 
            'Accept': 'application/json',
            'Content-type': 'application/json'
        }}

        if(payload == null || payload == undefined) {
            return new Promise((resolve, reject) => {
                reject({message: "payload is null or undefined"})
            })
        }

        return this.http.post(to , payload, config);
    }

    requestPut(to, payload)
    {
        let config = { headers: { 
            'Accept': 'application/json',
            'Content-type': 'application/json'
        }}

        if(payload == null || payload == undefined) {
            return new Promise((resolve, reject) => {
                reject({message: "payload is null or undefined"})
            })
        }

        return this.http.put(to , payload, config);
    }

    requestDelete(to)
    {  
        return this.http.delete(to);
    }
}