<template>
    <btn-rounded @click="$emit('click')"  tooltip="supprimer" i="minus" type="danger" :text="text"  :disabled="disabled" :loading="loading" :small="small" />
</template>
<script>
export default {
    name: "BtnMinus",
    props: {
        disabled: Boolean,
        loading: Boolean,
        small: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: 'retirer',
            required: false
        }
    }
}
</script>

