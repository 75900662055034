/**
 * build query string according to api platform requirement
 * @param object queries 
 * @returns String queryString
 */
export default function(queries) {
    if(queries == null || queries == undefined || typeof queries != "object") {
        return ""
    }

    // build query string
    let queryString = Object.keys(queries).reduce((queryString, key) => {
            switch (typeof queries[key]) {
                case "object": {
                    // if (Array.isArray(queries[key])) {
                    //     queryString += queries[key].reduce((s, value) => {
                    //         return `${s}${key}[]=${value}&`
                    //     }, "")
                    //     // queryString += key + "[]=" + queries[key].join() + "&"
                    // } else {
                    //     queryString += Object.keys(queries[key]).reduce((subQueryStirng, subKey) => 
                    //         `${subQueryStirng}${key}[${subKey}]=${queries[key][subKey]}&`,
                    //         ""
                    //     )
                    // }
                    break
                }
                default:
                    if(queries[key] != null && queries[key] != undefined && queries[key] != "") {
                        queryString += key + "=" + queries[key] + "&"
                    }
                    break
            }
            return queryString
        },
        "?"
    )

    //remove last &
    queryString = queryString.replace(/&$/, "").replace(/\?$/, "")
    
    return queryString
}





// /**
//  * build query string according to api platform requirement
//  * @param object queries 
//  * @returns String queryString
//  */
// buildQueryString(queries) {
//     if(queries == null || queries == undefined || typeof queries != "object") {
//         return ""
//     }

//     // build query string
//     let queryString = Object.keys(queries).reduce((queryString, key) => {
//             switch (typeof queries[key]) {
//                 case "object": {
//                     if (Array.isArray(queries[key])) {
//                         queryString += queries[key].reduce((s, value) => {
//                             return `${s}${key}[]=${value}&`
//                         }, "")
//                         // queryString += key + "[]=" + queries[key].join() + "&"
//                     } else {
//                         queryString += Object.keys(queries[key]).reduce((subQueryStirng, subKey) => 
//                             `${subQueryStirng}${key}[${subKey}]=${queries[key][subKey]}&`,
//                             ""
//                         )
//                     }
//                     break
//                 }
//                 case "string":
//                 default :
//                     if(queries[key] != null && queries[key] != undefined && queries[key] != "") {
//                         queryString += key + "=" + queries[key] + "&"
//                     }
//                     break
//             }
//             return queryString
//         },
//         "?"
//     )

//     //remove last &
//     queryString = queryString.replace(/&$/, "").replace(/\?$/, "")
    
//     return queryString
// }