<template>
    <btn-rounded @click="$emit('click')"  tooltip="modifier" i="pen" type="primary" text="modifier" :disabled="disabled" :loading="loading" :small="small" />
</template>
<script>
export default {
    name: "BtnEdit",
    props: {
        disabled: Boolean,
        loading: Boolean,
        small: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
