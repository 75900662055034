<template>
    <btn-rounded @click="$emit('click')"  tooltip="ajouter" i="plus" type="primary" :text="text" :disabled="disabled" :loading="loading" :small="small" />
</template>
<script>
export default {
    name: "BtnAdd",
    props: {
        disabled: Boolean,
        loading: Boolean,
        small: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: 'nouveau',
            required: false
        }
    }
}
</script>
