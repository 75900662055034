import Service from './Service'

export default class AuthService extends Service {
    constructor() {
        super(AuthService.API);
    }

    authenticate(email, password) {
        return this.requestPost(this.api + '/signin', {email: email, password: password});
    }
}

AuthService.API = ""