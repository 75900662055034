import Service from './Service'

export default class SubcategoryService extends Service {
    constructor() {
        super(SubcategoryService.API);
    }

    getFilters(id, signal) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet(this.api + '/' + id + '/filters', null, signal);
    }

    getSizes(id, signal) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet(this.api + '/' + id + '/sizes', null, signal);
    }

    setSizes(id, sizes) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/' + id + '/sizes', sizes);
    }
}

SubcategoryService.API = "/subcategories"