<template>
    <small :class="['badge', 'badge-' + type]">
        {{ value }}
    </small>
</template>

<script>
export default {
    name: "pill",
    props: {
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'primary'
        }
    }
}
</script>

<style scoped>

</style>