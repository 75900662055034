import Vue from 'vue';


//Buttons
import BtnCircle from './BtnCircle.vue'
import BtnRounded from './BtnRounded.vue'
import BtnCancel from './BtnCancel'
import BtnSave from "./BtnSave";
import BtnTrash from "./BtnTrash";
import BtnEdit from "./BtnEdit";
import BtnRefresh from "./BtnRefresh";
import BtnAdd from "./BtnAdd";
import BtnEye from "./BtnEye";
import BtnMinus from "./BtnMinus";

Vue.component('btn-circle', BtnCircle)
Vue.component('btn-rounded', BtnRounded)
Vue.component('btn-cancel', BtnCancel)
Vue.component('btn-save', BtnSave)
Vue.component('btn-trash', BtnTrash)
Vue.component('btn-edit', BtnEdit)
Vue.component('btn-refresh', BtnRefresh)
Vue.component('btn-add', BtnAdd)
Vue.component('btn-eye', BtnEye)
Vue.component('btn-minus', BtnMinus)
