import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/dashboard/DashboardView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/categories',
    name: 'categories',
    component: function () {
      return import('../views/categories/CategoriesView.vue')
    }
  },
  {
    path: '/subcategories',
    name: 'subcategories',
    component: function () {
      return import('../views/subcategories/SubcategoriesView.vue')
    }
  },
  {
    path: '/subcategories/:id',
    name: 'subcategory',
    props: true,
    component: function () {
      return import('../views/subcategories/SubcategoryView.vue')
    }
  },
  {
    path: '/items',
    name: 'items',
    component: function () {
      return import('../views/items/ItemsView.vue')
    }
  },
  {
    path: '/items/:id',
    name: 'item',
    props: true,
    component: function () {
      return import('../views/items/ItemView.vue')
    }
  },
  {
    path: '/items/:itemId/:itemColorId',
    name: 'itemColor',
    props: true,
    component: function () {
      return import('../views/itemColors/ItemColorView.vue')
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: function () {
      return import('../views/collections/CollectionsView.vue')
    }
  },
  {
    path: '/collections/:id',
    name: 'collection',
    props: true,
    component: function () {
      return import('../views/collections/CollectionView.vue')
    }
  },
  {
    path: '/marks',
    name: 'marks',
    component: function () {
      return import('../views/dashboard/DashboardView.vue')
    }
  },
  {
    path: '/users',
    name: 'users',
    component: function () {
      return import('../views/users/UsersView.vue')
    }
  },
  {
    path: '/users/:id',
    name: 'user',
    props: true,
    component: function () {
      return import('../views/users/UserView.vue')
    }
  },
  {
    path: '/*',
    name: 'not-found',
    component: function () {
      return import('../views/dashboard/DashboardView.vue')
    }
  }
  // {
  //   path: '/categories',
  //   name: 'categories',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/dashboard/DashboardView.vue')
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
