<template>
	<div id="app" class="wrapper">
		<HeaderNavigation></HeaderNavigation>
		<SidebarNavigation></SidebarNavigation>
		<!-- v-if="isReady" -->
		<template v-if="isAdmin">
			<!-- v-if="requireAuth" -->
			<!-- <Registration ></Registration> -->
			<!-- v-if="isAdmin" -->
			<div class="content-wrapper d-flex position-relatibe">
				<div class="content p-15p d-flex flex-column">
					<router-view :key="$route.path" class="m-0 flex-1"></router-view>
				</div>
			</div>
            <ErrorHandler></ErrorHandler>
		</template>
        <registration v-else></registration>
		 <!-- <div v-else class="content-wrapper d-flex justify-content-center align-items-center">
			<div class="embed-responsive embed-responsive-1by1 loader-container">
				 <div class="loader" v-resize="computeHeight">
                    <div class="spinner-border loader-spinner embed-responsive-item" role="status" ref="spinner">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> 
				<img class="loader" src="/assets/svgs/loader.svg" alt="">
			</div>
		</div>  -->
	</div>
</template>

<script>
import HeaderNavigation from "@/components/navigation/HeaderNavigation"
import SidebarNavigation from "@/components/navigation/SidebarNavigation"
import ErrorHandler from "@/components/widget/ErrorHandler"
import Registration from "@/components/auth/Registration";

export default { 
    components : {
        SidebarNavigation,
        HeaderNavigation,
        ErrorHandler,
        Registration
    },
    name: "Admin",
    data() {
        return {
            isReady: false
        }
    },
    mounted() {
        // this.getMe()
    },
    methods: {
        // getMe() {
        //     return this.$authService.me().then(res => {
        //         this.$store.dispatch("setMe", res.data)
        //         this.isReady = true
        //     })
        // }
    },
    computed: {
        // requireAuth() {
        //     return !this.$store.getters.isAdmin
        // },
        isAdmin() {
            return this.$store.getters.isAdmin
        }
    },
    watch: {
        // storeInitiated: function (newValue) {
        //     if(newValue && !this.isReady)
        //         this.getMe()
        // }
    }
}
</script>


<style lang="less" src="@/assets/styles/app.less"></style>



				<!-- <router-view class="content" :key="$route.path"></router-view> -->
				<!--        <div class="content flex-grow-1 ">-->
				<!--            <div v-if="$store.getters.messages.length" class="d-flex position-fixed w-100 " style="z-index: 1020;">-->
				<!--                <div class="container py-3 px-0">-->
				<!--                    <div :class="['alert', message.type === 'error' ? 'alert-danger' : 'alert-' + message.type]" v-for="(message, index) in $store.getters.messages">-->
				<!--                        <button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>-->
				<!--                        <strong>{{ message.type }}</strong> {{ message.message }}-->
				<!--                    </div>-->
				<!--                </div>-->
				<!--            </div>-->
				<!--        </div>-->