<template>
    <div class="login-container">
        <div class="login-box login-admin">
            <div class="card">
                <div v-if="!isAuthenticated" class="card-body login-card-body">
                    <p class="login-box-msg">Se connecter</p>
                    <section class="mb-2" :class="{ 'shake' : shakeMessage, 'invisible' : message === 'ok' }">
                        <div v-if="message.length" class="text-danger">
                            {{ message }}
                        </div>
                    </section>
                    <form>
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email" v-model="email" :class="{'is-invalid' : errors['email'] }">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>
                            <span v-for="error in errors['email']" id="collection_description-error" class="error invalid-feedback">{{ error }}</span>
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" class="form-control" placeholder="Password" v-model="password" :class="{'is-invalid' : errors['password'] }">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                            <span v-for="error in errors['password']" id="collection_description-error" class="error invalid-feedback">{{ error }}</span>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="icheck-primary">
                                    <input type="checkbox" id="remember" v-model="rememberMe">
                                    <label for="remember" class="ml-5p">
                                        Remember Me
                                    </label>
                                </div>
                            </div>
                            <div class="col-4">
                                <button type="submit" class="btn btn-primary btn-block" @click.prevent="authenticate">
                                    <div v-if="loading" class="spinner-border text-light" role="status" style="width: 20px; height: 20px;">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <template v-else>Log in</template>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-else class="card-body login-card-body d-flex flex-column">
                    <p class="login-box-msg">Votre compte ne possède pas les droits nécessaire pour continuer</p>
                    <p class="login-box-msg">Veuillez vous déconnecter et vous reconnecter avec un compte administrateur</p>
                    <button class="btn btn-outline-danger align-self-center" @click="logout">Déconnexion</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import errorsMixin from '@/mixins/errors';

export default {
    name: "Registration",
    mixins: [
        errorsMixin
    ],
    data(){
        return {
            email: "admin@mail.com",
            password: "password",
            rememberMe: false,
            message: "ok",
            loading: false,
            shakeMessage: false,
            errors: {}
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated
        },
        isAdmin() {
            return this.$store.getters.isAdmin
        }
    },
    methods: {
        authenticate() {
            this.loading = true
            this.shakeMessage = false
            this.message = "ok"

            this.$authService.authenticate(this.email, this.password)
            .then(res => {
                let auth = res.data
                this.$store.dispatch("setAuth", {auth: auth, rememberMe: this.rememberMe})

                this.message = "ok"
                this.loading = false;
            })
            .catch(error => {
                console.log(error)
                if (error.response?.status == 422) {
                    this.errors = this.addErrors(error.response.data, this.errors)
                    return
                }
                if (error.response?.status == 401) {
                    this.message = "Email ou mot de passe invalide"
                    this.shakeMessage = true
                    return
                }
                this.$store.dispatch("setError", error)
            })
            .finally(() => { 
                this.loading = false
            })
        },
        logout() {
            this.$store.dispatch("logout")
        }
    }
}
</script>

<style scoped>

</style>