import Service from './Service'

export default class FilterService extends Service {
    constructor() {
        super(FilterService.API);
    }

    addItem(id, itemId) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        if(itemId == null || itemId == undefined || itemId == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/' + id + '/items/' + itemId, {});
    }

    removeItem(id, itemId) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        if(itemId == null || itemId == undefined || itemId == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestDelete(this.api + '/' + id + '/items/' + itemId, {});
    }

    getFitlersWithItems(queries, signal) {
        return this.requestGet(this.api + '/items', queries, signal);
    }
}

FilterService.API = "/filters"