<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link :to="{ name: 'dashboard' }" class="brand-link">
            <img src="/assets/images/logo.png" alt="logo gexist" class="brand-image img-circle elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">Administration</span>
        </router-link>
        <div class="sidebar">
            <nav class="mt-4">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <router-link :to="{ name: 'dashboard' }" class="nav-link">
                            <i class="nav-icon fas fa-desktop"></i>
                            <p>
                                Accueil
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'categories' }" class="nav-link">
                            <i class="nav-icon fas fa-bookmark"></i>
                            <p>
                                Catégories
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'subcategories' }" class="nav-link">
                            <i class="nav-icon fas fa-bookmark"></i>
                            <p>
                                Sous-Catégories
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'items' }" class="nav-link">
                            <i class="nav-icon fas fa-tshirt"></i>
                            <p>
                                Articles
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'collections' }" class="nav-link">
                            <i class="nav-icon fas fa-bookmark"></i>
                            <p>
                                Collections
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'users' }" class="nav-link">
                        <i class="nav-icon fas fa-users"></i>
                            <p>
                                Utilisateurs
                            </p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'marks' }" class="nav-link">
                        <i class="nav-icon fas fa-star"></i>
                            <p>
                                Notes
                            </p>
                        </router-link>
                    </li>
<!--                    <li class="nav-item">-->
<!--                        <a href="#" class="nav-link">-->
<!--                            <i class="nav-icon fa fa-bookmark"></i>-->
<!--                            <p>-->
<!--                                Sous Catégories-->
<!--                                <i class="right fas fa-angle-left"></i>-->
<!--                            </p>-->
<!--                        </a>-->
<!--                        <ul class="nav nav-treeview">-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'subcategoriesList' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Voir toutes</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'subcategoryCreation'}" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Créer</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                        <a href="#" class="nav-link">-->
<!--                            <i class="nav-icon fas fa-tshirt"></i>-->
<!--                            <p>-->
<!--                                Produits-->
<!--                                <i class="right fas fa-angle-left"></i>-->
<!--                            </p>-->
<!--                        </a>-->
<!--                        <ul class="nav nav-treeview">-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'itemsList' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Voir tout les produits</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{'name': 'itemCreation' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Créer</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                        <a href="#" class="nav-link">-->
<!--                            <i class="nav-icon fas fa-box-open"></i>-->
<!--                            <p>-->
<!--                                Collections-->
<!--                                <i class="right fas fa-angle-left"></i>-->
<!--                            </p>-->
<!--                        </a>-->
<!--                        <ul class="nav nav-treeview">-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'collectionsList' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Voir toutes</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'collectionCreation' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Créer</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                        <a href="#" class="nav-link">-->
<!--                            <i class="nav-icon fas fa-film"></i>-->
<!--                            <p>-->
<!--                                Défilés-->
<!--                                <i class="right fas fa-angle-left"></i>-->
<!--                            </p>-->
<!--                        </a>-->
<!--                        <ul class="nav nav-treeview">-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'paradesList' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Voir tous</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'paradeCreation' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Créer</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                        <a href="#" class="nav-link">-->
<!--                            <i class="nav-icon fas fa-comment"></i>-->
<!--                            <p>-->
<!--                                Messages-->
<!--                                <i class="right fas fa-angle-left"></i>-->
<!--                            </p>-->
<!--                        </a>-->
<!--                        <ul class="nav nav-treeview">-->
<!--                            <li class="nav-item">-->
<!--                                <router-link :to="{ name: 'paradesList' }" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Voir tous</p>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li class="nav-item">-->
<!--                                <a href="#" class="nav-link">-->
<!--                                    <i class="far fa-circle nav-icon"></i>-->
<!--                                    <p>Créer</p>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                        <router-link :to="{ name: 'tagsList' }" class="nav-link">-->
<!--                            <i class="nav-icon fas fa-tags"></i>-->
<!--                            <p>-->
<!--                                Tags-->
<!--                            </p>-->
<!--                        </router-link>-->
<!--                    </li>-->
                    <li class="nav-item">
                        <a :href="gexitUrl" class="nav-link">
                            <i class="nav-icon fas fa-sign-out-alt"></i>
                            <p>
                                Site web
                            </p>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
export default {
    name: "SidebarNavigation",
    data() {
        return {
            gexitUrl: process.env.VUE_APP_HOST
        }
    },
    computed: {

    },
    watch:{
    },
    methods:{
    },
    created() {
    }
}
</script>

<style scoped>

</style>
