import Service from './Service'
import {BASE_API} from './api' 

export default class Collection extends Service {
    constructor() {
        super(Collection.API);
    }

    getItems(id, signal) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestGet(this.api + '/' + id + '/items', null, signal);
    }

    addItem(id, itemId) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        if(itemId == null || itemId == undefined || itemId == "") {
            return new Promise((resolve, reject) => {
                reject({message: "itemId is null or undefined"})
            })
        }

        return this.requestPost(this.api + '/' + id + '/items/' + itemId, {});
    }

    removeItem(id, itemId) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        if(itemId == null || itemId == undefined || itemId == "") {
            return new Promise((resolve, reject) => {
                reject({message: "itemId is null or undefined"})
            })
        }

        return this.requestDelete(this.api + '/' + id + '/items/' + itemId, {});
    }
}

Collection.API = "/collections"
export const API_COLLECTIONS_BANNER = "/banner";

export function getUploadBanner(id) {
    return BASE_API + Collection.API + "/" + id + API_COLLECTIONS_BANNER
}
