import axios from 'axios'
import store from "@/store"

export const HOST=process.env.VUE_APP_SERVER_HOST
export const BASE_API = HOST

let isAlreadyFetchingAccessToken = false;
let subscribers = []

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

const api = axios.create({
    baseURL: BASE_API,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*, Authorization",
        "Access-Control-Allow-Methods": "*",
    }
})
api.interceptors.request.use(
    config => {
        const token = store.getters.getToken;
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    error => {
        console.log(error)
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    }, error => {
        if (error.code == "ERR_CANCELED") {
            return Promise.resolve();
        }
        if (error.response == undefined) {
            return Promise.reject(error);
        }

        const { config, response: { status } } = error
        const originalRequest = config;

        if(status === 401 || status === 403) {
            if(store.state.auth === undefined || store.state.auth === null) {
                return Promise.reject(error)
            }

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true

                // instead of this store call you would put your code to get new token
                axios.post(BASE_API + '/refresh-token', {
                    refreshToken: store.getters.getRefreshToken
                }).then((res) => {
                    store.dispatch("refreshToken", res.data)
                    isAlreadyFetchingAccessToken = false
                    onAccessTokenFetched(res.data.token)
                }).catch((err) => {
                    store.dispatch("logout")
                    isAlreadyFetchingAccessToken = false
                    onAccessTokenFetched(null)
                })
            } 

            //console.log("add function to subscriber")
            return new Promise((resolve) => {
                addSubscriber(access_token => {
                    if(access_token) {
                        originalRequest.headers.Authorization = 'Bearer ' + access_token
                    }
                    resolve(axios(originalRequest))
                })
            })
        } 

        return Promise.reject(error)
    }
);

export default api

