<template>
    <div class="mymodal-container">
        <slot :onClick="open" name="btn"></slot>
        <div class="mymodal-overlay" v-if="isOpened">
            <div :class="['mymodal', classes, type]">
                <div class="mymodal-header">
                    <h5 class="mb-0 d-flex align-items-center">
                        <slot name="title"></slot>
                    </h5>
                    <div class="pointer p-5p" @click="close">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <div class="mymodal-divider"></div>
                <div :class="['mymodal-body', bodyClasses]">
                    <slot name="body"></slot>
                </div>
                <div class="mymodal-divider"></div>
                <div class="mymodal-footer d-flex justify-content-between">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "Default",
    components: {

    },
    mixins: [

    ],
    props: {
        title: {
            type: String,
            default: ""
        },
        classes: {
            type: String,
            default: ""
        },
        bodyClasses: {
            type: String,
            default: ""
        },
        type: {
            type: String, 
            default: "primary"
        }
    },
    data() {
        return  {
            isOpened: false
        }
    },
    computed: {

    },
    methods: {
        open() {
            this.isOpened = true
        },
        close() {
            this.isOpened = false
            this.$emit("closed")
        }
    },
    watch: {
    },
    setup() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    }
}
</script>