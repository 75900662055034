<template>
    <div class="error-handler-overlay" v-if="error != null && error != undefined">
        <div class="error-handler">
                <div class="error-handler-header">
                    <h5 class="mb-0 d-flex align-items-center">Une erreur est survenue</h5>
                    <div class="pointer p-5p" @click="close">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <div class="error-handler-divider"></div>
                <div class="error-handler-body">
                    {{ error.message }}
                    <div v-if="reason != ''" class="error-handler-detail">
                        <div class="pointer d-flex align-items-center error-handler-detail-title" @click=toggleReason>
                            Détails <i class="fas fa-chevron-right ml-10p error-handler-detail-icon" :class="{'opened': reasonIsOpened}"></i>
                        </div>
                        <div class="error-handler-reason-container mt-10p" :class="{'opened': reasonIsOpened}">
                            <div class="error-handler-reason">
                                <pre class="error-handler-reason">{{ reason }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorHandler",
    components: {},
    mixins: [],
    props: {},
    data() { return {
        reasonIsOpened: false
    } },
    computed: {
        error() {
            return this.$store.getters.getError
        },
        reason() {
            return this.$store.getters.getError.response?.data ?? ""
        }
    },
    methods: {
        close() {
            this.$store.dispatch("setError", null)
            this.reasonIsOpened = false
        },
        toggleReason() {
            this.reasonIsOpened = !this.reasonIsOpened
        }
    },
    watch: {
    },
    setup() {},
    created() {},
    mounted() {},
    beforeDestroy() {}
}
</script>