import Service from './Service'
import {BASE_API} from './api' 

export default class ItemColorService extends Service {
    constructor() {
        super(ItemColorService.API);
    }

    updateImages(id, images) {
        if(id == null || id == undefined || id == "") {
            return new Promise((resolve, reject) => {
                reject({message: "id is null or undefined"})
            })
        }

        return this.requestPut(this.api + '/' + id + "/images", images);
    }
}

ItemColorService.API = "/item-colors"
const API_IMAGES = "/images";

export function getUploadImageUrl(id) {
    return BASE_API + ItemColorService.API + "/" + id + API_IMAGES
}