const errorStore = {
    namespaces: true,
    state: () => ({
        error: null,
    }),
    getters: {
        getError(state) {
            return state.error
        },
    },
    mutations: {
        setError(state, error)
        {
            state.error = error
        },
    },
    actions: {
        setError({commit}, error)
        {
            return commit('setError', error)
        },
    }
}
export default errorStore